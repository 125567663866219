export default {
  props: {
    siteId: {
      type: String,
      required: true
    },
    reportId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      observing: false
    };
  },
  provide() {
    return {
      $reportProvider: () => {
        return this.$_.pick(this, [
          "timeframe",
          "site",
          "fqdn",
          "fqdn",
          "tasks",
          "tasksCompleted",
          "totalTasks",
          "totalTaskPosts",
          "totalTaskAttachments",
          "totalTasksCompleted",
          "fileBackupConfig",
          "fileSnapshots",
          "fileSnapshotsAvgSize",
          "dbSnapshots",
          "dbSnapshotsAvgSize",
          "totalFileSnapshots",
          "totalDbSnapshots",
          "monitor",
          "monitorUptime",
          "monitorDowntime",
          "monitorResponseTime",
          "attachments",
          "totalAttachments"
        ]);
      }
    };
  },
  computed: {
    loading() {
      return this.$_.isEmpty(this.report);
    },
    report() {
      return this.$store.getters["sites/site/report"]({
        siteId: this.siteId,
        reportId: this.reportId
      });
    },
    reportData() {
      return this.report.data || {};
    },
    timeframe() {
      return this.$moment(
        new Date(this.reportData.year, this.reportData.month - 1)
      ).format("MMMM YYYY");
    },
    site() {
      return this.$_.get(this.reportData, "site");
    },
    fqdn() {
      return this.$_.get(this.reportData, "site.fqdn");
    },
    tasks() {
      return this.$_.sortBy(this.$_.get(this.reportData, "tasks"), [
        "dateCreated.seconds"
      ]);
    },
    tasksCompleted() {
      return this.$_(this.tasks)
        .filter(task => {
          return ["0-01-complete", "0-01-cancelled"].includes(task.status);
        })
        .value();
    },
    totalTasks() {
      return this.tasks.length;
    },
    totalTaskPosts() {
      return this.$_(this.tasks).sumBy(task =>
        this.$_.get(task, "stats.totalPosts", 0)
      );
    },
    totalTaskAttachments() {
      return this.$_(this.tasks).sumBy(task =>
        this.$_.get(task, "stats.totalAttachments", 0)
      );
    },
    totalTasksCompleted() {
      return this.tasksCompleted.length;
    },
    monitor() {
      return this.$_.get(this.reportData, "monitoring", {});
    },
    monitorUptime() {
      const uptime = this.$_.get(this.monitor, "custom_uptime_ratio");
      return uptime ? `${this.$_.round(parseFloat(uptime), 2)}%` : `…`;
    },
    monitorDowntime() {
      const uptime = this.$_.get(this.monitor, "custom_uptime_ratio");
      return uptime ? `${this.$_.round(100 - parseFloat(uptime), 2)}%` : `…`;
    },
    monitorResponseTime() {
      const avgResponse = this.$_.get(
        this.monitor,
        "average_response_time",
        null
      );
      return avgResponse ? `${avgResponse}ms` : "…";
    },
    fileBackupConfig() {
      return this.$_.get(this.reportData, "fileBackup", {});
    },
    fileSnapshots() {
      return this.$_.get(this.fileBackupConfig, "snapshot", []);
    },
    totalFileSnapshots() {
      return this.fileSnapshots.length;
    },
    fileSnapshotsAvgSize() {
      return this.$_.round(
        this.$_(this.fileSnapshots).sumBy(snapshot => snapshot.size || 0) /
          this.totalFileSnapshots
      );
    },
    dbSnapshots() {
      const dbBackups = this.$_.get(this.reportData, "dbBackups");
      if (!this.$_.isArray(dbBackups)) return [];
      return this.$_.flatMap(dbBackups, db => {
        return db.backups;
      });
    },
    totalDbSnapshots() {
      return this.dbSnapshots.length;
    },
    dbSnapshotsAvgSize() {
      return this.$_.round(
        this.$_(this.dbSnapshots).sumBy(snapshot => snapshot.size || 0) /
          this.totalDbSnapshots
      );
    },
    attachments() {
      return this.$_.get(this.report, "files", []);
    },
    totalAttachments() {
      return this.attachments.length;
    }
  },
  beforeDestroy() {
    if (this.observing) {
      this.unobserveReport();
    }
  },
  created() {
    this.observeReport();
  },
  methods: {
    observeReport() {
      this.observing = true;
      return this.$store
        .dispatch("sites/observeReport", {
          siteId: this.siteId,
          reportId: this.reportId
        })
        .then(reportExists => {
          if (!reportExists) this.reportNotFound();
        })
        .catch(error => {
          console.error(error.message);
          this.reportNotFound();
        });
    },
    unobserveReport() {
      this.$store.dispatch("sites/unobserveReport", {
        siteId: this.siteId,
        reportId: this.reportId
      });
    },
    reportNotFound() {
      this.$router.push(`/sites/${this.siteId}`);
      this.$toast.open({
        message: `Report not found`,
        type: "is-danger"
      });
    }
  }
};
