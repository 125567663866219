<template>
  <loading v-if="loading" centered />
  <div v-else class="site-monthly-report">
    <report-header-ribbon />
    <report-tasks-ribbon class="has-background-light" />
    <report-monitoring-ribbon class="has-background-white has-border-top" />
    <report-maintenance-ribbon class="has-background-light has-border-top" />
    <report-attachments-ribbon
      :site-id="siteId"
      :report-id="reportId"
      class="has-background-white has-border-top"
    />
  </div>
</template>

<script>
import reportProvider from "@shared/sites/report/_reportProvider";
export default {
  name: "SiteMonthlyReport",
  components: {
    "report-header-ribbon": () =>
      import("@shared/sites/report/_reportHeaderRibbon"),
    "report-tasks-ribbon": () =>
      import("@shared/sites/report/_reportTasksRibbon"),
    "report-monitoring-ribbon": () =>
      import("@shared/sites/report/_reportMonitoringRibbon"),
    "report-maintenance-ribbon": () =>
      import("@shared/sites/report/_reportMaintenanceRibbon"),
    "report-attachments-ribbon": () =>
      import("@shared/sites/report/_reportAttachmentsRibbon")
  },
  mixins: [reportProvider]
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.box.dashboard-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include mobile {
    span {
      width: 98%;
    }
  }
}
</style>
